import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  URL: string
}

export const Path = memo(function Path({ URL }: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="466.499"
      height="540.002"
      viewBox="0 0 466.499 540.002"
    >
      <defs>
        <pattern id="intro-pattern" width="1" height="1">
          <image
            preserveAspectRatio="xMaxYMid slice"
            width="100%"
            height="100%"
            href={URL}
          />
        </pattern>
      </defs>
      <g transform="translate(-144 -1099.999)">
        <path
          className="intro-path"
          d="M11476,657.939h0a272,272,0,0,1-54.414-5.485,268.54,268.54,0,0,1-96.545-40.627,270.785,270.785,0,0,1-97.822-118.792,268.655,268.655,0,0,1-15.732-50.681,272.636,272.636,0,0,1,0-108.829,268.528,268.528,0,0,1,40.626-96.546,270.783,270.783,0,0,1,118.793-97.823,268.608,268.608,0,0,1,50.681-15.733,272,272,0,0,1,54.414-5.485v540Z"
          transform="translate(-11062 982.063)"
          fill="#4E738A"
        />
        <path
          d="M680.569,582.92l-85.646-85.9a47.262,47.262,0,0,1-14.4-34.749c0-13.785,4.681-25.683,14.4-35.063,9.393-9.7,20.967-14.41,34.745-14.41s25.041,5,34.763,14.41l86.084,86.336q27.067-44.65,27.072-100.418c0-53.944-19.136-100.36-57.4-138.311-38.262-38.56-84.35-57.692-138.6-57.692-53.951,0-100.343,19.133-138.605,57.692-38.262,37.951-57.4,84.367-57.4,138.311,0,54.234,19.133,100.339,57.4,138.905,38.262,37.958,84.654,57.084,138.605,57.084Q636.639,609.117,680.569,582.92Z"
          transform="translate(-167.588 956.876)"
          fill="url(#intro-pattern)"
        />
      </g>
    </Container>
  )
})

const Container = styled.svg`
  width: 100%;
  height: auto;

  .intro-path {
    fill: ${({ theme }) => theme.colors.variants.primaryLight};
  }
`
